import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.17093 15.6944C8.76343 16.1019 8.10274 16.1019 7.69523 15.6944L0.000861168 8L7.69523 0.305628C8.10274 -0.101875 8.76343 -0.101875 9.17093 0.305628C9.57844 0.713131 9.57844 1.37383 9.17093 1.78133L2.95226 8L9.17093 14.2187C9.57844 14.6262 9.57844 15.2869 9.17093 15.6944Z"
      fill="#27272C"
    />
  </svg>
);
export default SvgArrowBack;
